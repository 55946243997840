<template>
  <!-- カタログ名寄せ情報 -->
  <b-card no-body>
    <b-card-body>
      <b-card-title title-tag="h2"> カタログ名寄せ情報 </b-card-title>
      <b-card-text>
        <b-icon icon="info-circle-fill" variant="csub"></b-icon>
        選択したカタログの現在の蓄積データから、同一PLR-IDの人数や他の共通データを検索します。
      </b-card-text>
      <b-alert show v-if="error1" variant="caccent">
        エラー: 蓄積データの取得に失敗しました。
      </b-alert>
      <b-form-row>
        <b-col cols="6" sm="4" lg="2">
          <b-button
            class="w-100 mb-3"
            variant="csub"
            v-on:click="getAccumulatedData()"
          >
            蓄積データ取得
          </b-button>
        </b-col>
        <b-col cols="6" sm="4" lg="2">
          <b-button
            class="w-100 mb-3"
            variant="outline-secondary"
            v-on:click="catalogSelectClear()"
          >
            カタログ選択クリア
          </b-button>
        </b-col>
      </b-form-row>
      <b-card no-body v-if="workflowList.length > 0" class="border-0">
        <b-card-body v-for="wf in workflowList" v-bind:key="wf.workflowCode">
          <b-card-title title-tag="h3" class="font-weight-bold text-cmain">
            {{ wf.workflowCode }} {{ wf.workflowName }}
          </b-card-title>
          <div v-if="wf.eventList.length > 0">
            <div v-for="event in wf.eventList" v-bind:key="event.code">
              <b-card-title title-tag="h4" class="text-csub">
                {{ event.code }} {{ event.name }}
              </b-card-title>
              <b-table
                v-bind:fields="thingListFields"
                v-bind:items="event.thingList"
                responsive
                bordered
                sticky-header="400px"
                head-variant="dark"
              >
                <template v-slot:cell(check)="row">
                  <b-form-checkbox
                    v-bind:value="{
                      workflowCode: wf.workflowCode,
                      thingCode: row.item.code,
                    }"
                    v-model="dataChecked"
                    class="box-large"
                  ></b-form-checkbox>
                </template>
              </b-table>
            </div>
          </div>
          <div v-else>蓄積データがありません。</div>
        </b-card-body>
      </b-card>
      <b-card no-body v-if="workflowList.length > 0">
        <b-card-body>
          <b-form-row>
            <b-col cols="6" sm="4" lg="2">
              <b-button
                class="w-100 mb-3"
                variant="csub"
                v-on:click="checkCommonPlrIds()"
              >
                共通PLR-IDの確認
              </b-button>
            </b-col>
            <b-col cols="6" sm="4" lg="2">
              <b-button
                class="w-100 mb-3"
                variant="outline-secondary"
                v-on:click="dataSelectClear()"
              >
                データ選択クリア
              </b-button>
            </b-col>
          </b-form-row>
          <b-alert show v-if="error2" variant="caccent">
            エラー: 共通PLR-IDの確認に失敗しました。
          </b-alert>
          <b-card-text class="align-self-center"
            >チェックしたデータに共通するPLR-ID数
            <span class="py-2 px-5 border lead text-caccent">
              {{ numOfCommonPeople }} </span
            >人
          </b-card-text>
          <b-card-text>
            チェックしたデータに共通するPLR-IDが蓄積している他のデータ
          </b-card-text>
          <base-table
            v-bind:items="otherDataList"
            v-bind:fields="otherDataListFields"
          />
        </b-card-body>
      </b-card>
    </b-card-body>
  </b-card>
</template>

<script>
import BaseTable from '@/components/common/BaseTable'
export default {
  components: {
    BaseTable,
  },
  props: {
    targetList: { type: Array, default: () => [] },
  },
  data() {
    return {
      workflowList: [],
      dataChecked: [],
      thingListFields: [
        { key: 'check', label: '', tdClass: 'text-center' },
        { key: 'code', label: '', tdClass: 'text-center' },
        { key: 'name', label: 'モノ名称', tdClass: 'cell-break-small' },
        { key: 'description', label: 'モノ概要', tdClass: 'cell-break' },
        { key: 'dataAmount', label: '蓄積量', tdClass: 'text-right' },
        {
          key: 'numOfPeople',
          label: '蓄積人数',
          tdClass: 'text-right bg-csub-light',
        },
      ],
      numOfCommonPeople: null,
      otherDataList: [],
      otherDataListFields: [
        { key: 'workflowCode', label: 'WFコード', tdClass: 'text-center' },
        { key: 'workflowName', label: 'WF名称' },
        { key: 'thingCode', label: 'モノコード' },
        { key: 'thingName', label: 'モノ名称', tdClass: 'cell-break-small' },
        { key: 'thingDescription', label: 'モノ概要', tdClass: 'cell-break' },
        {
          key: 'numOfPeople',
          label: '共通人数',
          tdClass: 'text-right bg-csub-light',
        },
      ],
      error1: false,
      error2: false,
    }
  },
  methods: {
    getAccumulatedData() {
      // 蓄積データ取得
      console.log('** get accumulated data **', this.targetList)
      if (this.targetList.length == 0) {
        alert('カタログが一つも選択されていません。')
        return
      }
      const targetListSorted = [...this.targetList]
      targetListSorted.sort((a, b) => a - b)
      this.error1 = false
      this.$axiosToken
        .post('/catalog/workflows/accumulated_data', {
          targetList: targetListSorted,
        })
        .then((response) => {
          console.log(response)
          this.workflowList = response.data.workflowList
          this.numOfCommonPeople = null
          this.otherDataList = []
          this.dataChecked = []
        })
        .catch((error) => {
          console.log(error)
          this.error1 = true
        })
    },
    checkCommonPlrIds() {
      // 共通データを持つ人数確認など
      console.log('** check common ids **', this.dataChecked)
      if (this.dataChecked.length == 0) {
        alert('データが一つも選択されていません。')
        this.numOfCommonPeople = null
        this.otherDataList = []
        return
      }
      this.error2 = false
      this.$axiosToken
        .post('/catalog/workflows/accumulated_data/common_plr_ids', {
          targetList: this.dataChecked,
        })
        .then((response) => {
          console.log(response)
          this.numOfCommonPeople = response.data.numOfCommonPeople
          this.otherDataList = response.data.otherDataList
        })
        .catch((error) => {
          console.log(error)
          this.error2 = true
          this.numOfCommonPeople = null
          this.otherDataList = []
        })
    },
    catalogSelectClear() {
      // カタログ選択クリア
      this.error1 = false
      this.error2 = false
      this.dataChecked = []
      this.numOfCommonPeople = null
      this.otherDataList = []
      this.workflowList = []
      this.$emit('check-clear')
    },
    dataSelectClear() {
      // データ選択クリア
      this.error2 = false
      this.dataChecked = []
      this.numOfCommonPeople = null
      this.otherDataList = []
    },
  },
}
</script>
