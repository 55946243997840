<template>
  <!-- カタログ検索 -->
  <b-container class="py-4">
    <h1>データカタログ検索</h1>
    <b-button
      v-on:click="updateCatalogs()"
      variant="outline-secondary"
      class="mb-3"
    >
      カタログ再取得
    </b-button>
    <div v-if="isProcessing" class="text-caccent text-center">
      <b-icon icon="circle-fill" animation="throb"></b-icon>
      カタログ再取得中
    </div>
    <section id="search" class="mb-5">
      <!-- 検索 -->
      <b-card no-body>
        <b-card-body>
          <b-card-title title-tag="h2" class="d-none">
            カタログ検索
          </b-card-title>
          <b-card-text>
            <b-icon icon="info-circle-fill" variant="csub"></b-icon>
            複数ワードの場合、スペース区切りで入力してください。
          </b-card-text>
          <b-form v-on:submit.prevent="getSearchResult()">
            <b-form-row>
              <b-col md="6">
                <base-input
                  v-model="search.word"
                  label="検索ワード"
                  id="search-word"
                >
                </base-input>
              </b-col>
              <b-col md="3">
                <base-select
                  v-model="search.regionCode"
                  label="プロジェクトカテゴリー"
                  id="search-region-code"
                  value-field="code"
                  text-field="name"
                  v-bind:options="regions"
                >
                </base-select>
              </b-col>
              <b-col md="3">
                <base-select
                  v-model="search.categoryCode"
                  label="データカテゴリー"
                  id="search-category-code"
                  value-field="code"
                  text-field="name"
                  v-bind:options="categories"
                >
                </base-select>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col offset-sm="4" sm="4" offset-lg="5" lg="2">
                <b-button
                  type="submit"
                  variant="csub"
                  class="w-100"
                  v-bind:disabled="isProcessing"
                >
                  <b-icon icon="search"></b-icon>
                  検索
                </b-button>
              </b-col>
            </b-form-row>
          </b-form>
        </b-card-body>
      </b-card>
    </section>
    <section id="list" class="mb-5">
      <!-- 検索結果一覧 -->
      <h2 class="lead text-csub">検索結果：{{ count }}件</h2>
      <div class="lead text-csub">{{ message }}</div>
      <b-table
        v-bind:fields="workflowListFields"
        v-bind:items="workflowList"
        responsive
        selectable
        select-mode="single"
        v-on:row-selected="onRowSelected"
        sticky-header="600px"
      >
        <template v-slot:cell(check)="row">
          <b-form-checkbox
            v-bind:value="row.item.code"
            v-model="checked"
            class="box-large"
          ></b-form-checkbox>
        </template>
        <template v-slot:cell(content)="row">
          <b-card no-body class="border-0">
            <b-form-row no-gutters>
              <b-col lg="2" class="text-center align-self-center">
                <b-img
                  v-if="row.item.imageUri"
                  v-bind="imageProps"
                  v-bind:src="row.item.imageUri"
                  alt="Image"
                ></b-img>
                <b-img v-else v-bind="blankProps" alt="No Image"></b-img>
              </b-col>
              <b-col lg="10">
                <b-card-body>
                  <b-card-text class="text-cmain font-weight-bold">
                    {{ row.item.code }} {{ row.item.name }}
                  </b-card-text>
                  <b-card-text>
                    {{ row.item.description }}
                  </b-card-text>
                  <b-card-text class="text-csub">
                    {{ row.item.wfpPeriod }} {{ row.item.wfpName }}
                  </b-card-text>
                </b-card-body>
              </b-col>
            </b-form-row>
          </b-card>
        </template>
      </b-table>
    </section>
    <section id="common-plr-ids" class="mb-5">
      <!-- 共通蓄積データなど確認 -->
      <common-info
        v-bind:target-list="checked"
        v-on:check-clear="checked = []"
      />
    </section>
  </b-container>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import BaseSelect from '@/components/common/BaseSelect'
import CommonInfo from '@/components/catalog/CommonInfo'
export default {
  components: {
    BaseInput,
    BaseSelect,
    CommonInfo,
  },
  data() {
    return {
      search: {
        word: null,
        regionCode: null,
        categoryCode: null,
      },
      regions: [],
      categories: [],
      count: 0,
      workflowList: [],
      workflowListFields: [
        {
          key: 'check',
          tdClass: 'bg-white align-middle',
          thClass: 'border-0',
          label: '',
        },
        {
          key: 'content',
          tdClass: 'bg-white cell-break',
          thClass: 'border-0',
          label: '',
        },
      ],
      imageProps: { width: 140, height: 140 },
      blankProps: {
        blank: true,
        blankColor: '#e7e7e7',
        width: 140,
        height: 140,
      },
      checked: [],
      message: null,
      isProcessing: false,
    }
  },
  created() {
    this.getSearchSelectItems()
    this.getSearchResult()
  },
  methods: {
    updateCatalogs() {
      // PLR基盤からのカタログデータ取得
      this.isProcessing = true
      this.workflowList = []
      this.count = 0
      this.$axiosToken
        .post('/catalog/update_all')
        .then((response) => {
          console.log(response)
          this.getSearchResult()
        })
        .catch((error) => {
          console.log(error)
          alert('カタログの再取得に失敗しました。')
        })
        .finally(() => {
          this.isProcessing = false
        })
    },
    getSearchSelectItems() {
      // リスト選択項目取得
      console.log('** get search select items **')
      this.$axiosToken
        .get('/catalog/regions')
        .then((response) => {
          console.log(response)
          this.regions = response.data.regionList
          this.regions.unshift({ code: null, name: '' })
        })
        .catch((error) => {
          console.log(error)
        })
      this.$axiosToken
        .get('/catalog/data_categories')
        .then((response) => {
          console.log(response)
          this.categories = response.data.categoryList
          this.categories.unshift({ code: null, name: '' })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getSearchResult() {
      // ワークフロー一覧取得
      console.log('** get workflow list **')
      this.message = null
      this.checked = []
      this.$axiosToken
        .get('/catalog/workflows', {
          params: {
            search_word: this.search.word,
            region_code: this.search.regionCode,
            category_code: this.search.categoryCode,
          },
        })
        .then((response) => {
          console.log('** workflow list **', response)
          this.workflowList = response.data.workflowList
          this.count = this.workflowList.length
          if (this.count == 0) {
            this.message = '条件に一致するカタログが見つかりませんでした。'
          }
          for (let i = 0; i < this.count; i++) {
            const imageUri = this.workflowList[i].imageUri
            if (imageUri) {
              this.workflowList[i].imageUri =
                imageUri + '?' + new Date().getTime()
            }
          }
        })
        .catch((error) => {
          console.log(error)
          this.workflowList = []
          this.count = 0
          if (error.response) {
            console.log(error.response)
            this.message = 'エラー: ' + error.response.data.detail
          } else {
            this.message = 'エラー: カタログの取得に失敗しました。'
          }
        })
    },
    onRowSelected(item) {
      // 個別ページへ遷移
      console.log('** row selected **', item[0]['code'])
      this.$router
        .push({
          name: 'catalog-details',
          params: { workflowCode: item[0]['code'] },
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
